@import "./../../assets/css/variables";

.navbarContainer {
    position:absolute;
    z-index:2;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .logo {
        margin-top: 20px;
        margin-left:150px;
        width:150px;
    }

    .topNav {
        font-family:$paragraphFont;
        margin-left:auto;
        margin-right:50px;
        padding-left:0;
        top: 0;
        list-style-type: none;
        display: flex;
        right: 0;
        li {
            a {
                color: $offWhite;
                text-decoration:none;
            }
            padding:10px 0;
            margin:0 20px;
            overflow:hidden;
            position:relative;
            cursor:pointer;
            &:hover {
                transition: all 0.3s ease-in-out;
                color:$accentColor;
            }
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: $accentColor;
                visibility: hidden;
                transition: all 0.3s ease-in-out;
              }
            &:hover:before {
                visibility: visible;
                width: 100%;
              }
        }
        .underline {
            padding-bottom: 9px;
            border-bottom: 1px solid $offWhite;
        }
    }
}
@media screen and (min-width:991.01px) {
    .hideOnDesktop {
        display:none;
    }
}
@media screen and (max-width:991px) {
    /* Style the navigation menu */
.navbarContainer {
    overflow: hidden;
    background-color: $accentColor;
    position: relative;
    flex-direction:column;
    .logo {
        margin-top:10px;
        margin-bottom:10px;
        margin-left:0;
    } 
    ul,li {
        display:block;
        text-align: center;
    }
    ul {
        margin-left:0 !important;
        margin-right:0 !important;
    }
  }

  .mobileMenu {
    display: flex;
    align-items: center;
    color: $offWhite;
    width: 100%;
    justify-content: space-between;
    padding: 0 2em;
    box-sizing: border-box;
    span svg {
        font-size: 1.5em;
    }
  }
  
  /* Hide the links inside the navigation menu (except for logo/home) */


  .mobileDisplayNone {
    display: none !important;
  }

  .mobileDisplayBlock {
    display: block !important;
  }
  
  /* Style navigation menu links */
  .topNav a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }
  
  /* Style the active link (or home/logo) */
  .underline {
    background-color: $backgroundColor;
    color: white;
    border-bottom:none !important;
  }
}
@import "./../../assets/css/variables";

.goTopHidden {
    display:none;
}

.goTop {
    display:block;
}

.aboutUs {
    background:$backgroundColor;
    background-repeat:no-repeat;
    background-image:url(./../../assets/images/aboutusbanner.png);
    background-attachment: fixed;
    color:$offWhite;
    font-family:$paragraphFont;
    section {
        padding: 0 15px;
    }
    h1, h2 {
        font-family:$titleFont;
        &:after {
            background: none repeat scroll 0 0 $offWhite;
            bottom: -30px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 65px;
            margin: 0 auto;
         }
    }

    .banner {
        box-sizing: border-box;
        width:100%;
        height:800px;
        .introSection {
            padding-top:13em;
            display:flex;
            flex-direction: row-reverse;
            .introtext {
                width:50%;
                text-align: center;
                padding: 0 8%;
                box-sizing: border-box;
                h1 {
                    font-size: 3em;
                }
            }
        }
    }
    .infoSection {
        background:$backgroundColor;
        .actualInfo {
            padding-top:2em !important;
            padding-bottom:2em !important;
        }
        .info {
            margin:0 auto;
            max-width:1200px;
            .row {
                display:flex;
                margin: 0 -15px;
                .colLg12 {
                    padding: 0 15px;
                    box-sizing: border-box;
                    width:100%;
                    h2 {
                        font-size:2.5em;
                        text-align:center;
                        margin-bottom: 2em;
                    }
                    
                }
                .collg4 {
                    width:33.33%;
                    padding: 0 15px;
                    margin-top: -135px;
                    box-sizing: border-box;
                    .imageColumn {
                        position:relative;
                        box-sizing: border-box;
                        min-height:250px;
                        width:100%;
                        padding:150px 0 0 0;
                        transition: background-position-x 0.3s ease; // liever div beetje moven = transformX(10) ofiet
                        background-position-x: 0%;
                        background-size: contain;
                        .groupcard {
                            display: inline-block;
                            background: $accentColor;
                            color: white;
                            padding: 10px 30px;
                            box-sizing: border-box;
                            opacity:1;
                            transition:opacity 0.3s linear;
                            h4 {
                                margin:0;
                                font-weight:300;
                            }
                            p {
                                margin:0;
                            }
                        }

                        &:hover {
                            transition: background-position-x 0.3s ease;
                            background-position-x: 5%;
                            .groupcard {
                                opacity:0 !important;
                                display:none;
                            }
                        }
                        .overlay {
                            top:0;
                            position:absolute;
                            width:100%;
                            height:100%;
                            background-color:rgba(0, 0, 0, 0.7);
                            transition: all 0.2s ease-in-out;
                            padding: 20px;
                            box-sizing: border-box;
                            display: flex;
                            text-align:center;
                            align-items: center;
                            p {
                                opacity:0;
                                transition: all 0.2s ease-in-out;
                            }
                            &:hover {
                                cursor:pointer;
                                transition: all 0.2s ease-in-out;
                                background-color:rgba(255, 0, 118, 0.8);
                                p {
                                    opacity:1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .assetSection {
        background: $accentColor;
        .assets {
            margin:0 auto;
            max-width:1200px;
            color:white;
            display: flex;
            justify-content: space-around;
            .asset {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 0 30px;
                .number {
                    font-size:50px;
                    font-weight:bold;
                }
                .assetItem {
                    font-weight: 300;
                }
            }
        }
    }
}

@media screen and (max-width:991px) {

    .groupcard {
        display:none !important;
    }
    .row {
        flex-direction:column;
    }
    .collg4 {
        width:100% !important;
        flex-shrink: 0;
        margin-top:0 !important;
        .imageColumn {
            margin-bottom: 2em;
        }
    }

    .banner {
        height: auto !important;
        padding: 0 !important;
    }

    .introtext {
        width:100% !important;
        div {
            background: rgba(0, 0, 0, 0.6);
            padding: 2em;
        }
        margin-bottom:2em;
    }

    .overlay {
        opacity:1;
        background-color:rgba(255, 0, 118, 0.8) !important;
        display: flex;
        align-items: center;
        p {
            opacity: 1 !important;
            &::before {
                content: "WIST JE DAT?";
                display: block;
                font-family: $titleFont;
                font-size: 1.5em;
                margin-bottom: 1em;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .introSection {
        padding-top:2em !important;
    }
    .assets {
        flex-direction:column;
    }
}
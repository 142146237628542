@import "./../../assets/css/variables";

.homepage {
    color: $offWhite;
    font-family: $paragraphFont;
    font-weight:100;

    .instaFeedWrapper {
        max-width: 100%;
        overflow: hidden;
    }
    .bannerImg {
        width:100%;
        height:100vh;
        object-fit:cover;
        object-position:center;
        display:block; /*to make it behave as div and avoir whitespace issue*/
    }

   .overlay {
       top:0;
       position:absolute;
       width:100%;
       height: 100%;
       background-color:rgba(0.54, 0.54, 0.54, 0.5);
       .mainInfo {
        text-align: left;
        margin: 45vmin 15vh 0;
        font-family:$titleFont;
           h1 {
            font-weight:600;
            font-size: 3em;
            margin-bottom:0;
           }
           p {
                margin-top: 0.5em;
                font-family:$paragraphFont;
                max-width:500px;
           }

           .mainButton {
                color:inherit;
                text-decoration:none;
               margin-top:25px;
               display:inline-block;
               padding: 15px 50px;
               border: 1px solid #fdf9c5;
               font-family:$paragraphFont;
               &:hover {
                transition: all 0.3s ease-in-out;
                cursor:pointer;
                color:$accentColorLighter;
                border-color:$accentColor;
            }
           }
       }
   }

   .lastNews {
    .titleMarkup {
        margin: 4em 0;
        h2 {
            font-family:$titleFont;
            font-weight:$titleWeight;
            text-align:center;
            font-size:3em;
            margin-top: -0.3em;
            text-transform:uppercase;
        }
        h3 {
            font-size: 1.2em;
            text-align: center;
            font-weight:100;
            &::after {
                display: block;
                content: "";
                width: 150px;
                height: 2px;
                background: $accentColor;
                margin: 16px auto;
            }
        }
    }
    .readMore {
        background: $accentColor;
        display: inline-block;
        padding: 10px 20px;
        text-decoration: none;
        color: white;
        font-weight: 600;
    }

    .blogHover {
        background:$offWhite;
        position:absolute;
        z-index:3;
        top:0;
        width: 100%;
        height: 100%;
        opacity:0;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            color:$backgroundColor;
            padding:0.5em 1em;
            border: 2px solid $backgroundColor;
            font-weight:600;
            text-decoration:none;
            font-size:1.2em;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform:scale(1.05);
                background:$accentColor;
                color:white;
            }
        }
    }

    div[class^="col-lg-6"] .blogWrapper {
        position:relative;
        max-height:550px;
        overflow:hidden;
        margin-bottom:2em;
        &:hover {
            .blogHover {
                opacity:1;
                cursor:pointer;
            }
        }
        article {
            background:#141717;
            h3 {
                font-size: 1em;
                font-weight: 400;
            }
            h1 {
                font-size: 1.5em;
            }
        }
    }

    .gradient {
        position:absolute;
        z-index:2;
        right:0; bottom:0; left:0;
        height:200px;
        background: linear-gradient(rgba(28, 31, 31, 0), rgba(28, 31, 31, 1));
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
    
    }
}
}

@media screen and (max-width:768px) {

    .mainInfo {
        margin:0 !important;
        padding: 2em;
        text-align:center !important;
        p {
            margin-left:auto;
            margin-right:auto;
        }
    }
    div[class^="row"] {
        flex-direction:column;
    }

    div[class^="col-"] {
        width:100%;
    }

    div[class^="col-lg-6"] .blogWrapper {
            h3 {
                font-size: 1em;
                font-weight: 400;
            }
            h1 {
                font-size: 1.5em;
                font-family:$titleFont;
            }
    }

    .bannerImg {
        height: 58vh !important;
    }

    .titleMarkup h2 {
        font-size: 2em !important;
        margin-top: 0 !important;
    }
}
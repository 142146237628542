@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.events .react-calendar {
  width: 100%; 
  background: transparent;
  border: none;
  font-family: 'Quicksand',sans-serif;
}

.events .react-calendar__navigation__label, .events .react-calendar__navigation button:disabled {
  background:transparent;
}

.events .react-calendar__navigation button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.events .react-calendar__navigation button:not([disabled]):hover, .events .react-calendar__navigation button:not([disabled]):focus {
  background: #ff0076;
}

.react-calendar__navigation  {
    margin: 0 auto;
    display: flex;
    max-width: 600px;
    margin-bottom:50px;
}

.react-calendar__navigation button {
    padding:20px;
    font-family:inherit;
    background:transparent;
    color:antiquewhite;
    font-size:1.5em;
    font-weight:bold;
    border:none;
}

.react-calendar__navigation button:hover {
    cursor:pointer;
}

.react-calendar__navigation__label__labelText {
    text-transform: capitalize;
}

.react-calendar__month-view__weekdays__weekday {
    text-align:center;
    font-weight:bold;
    padding-bottom:10px;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration:none;
}

/* END CALENDAR STYLES */

.Dropdown-placeholder.is-selected {
  color:#b3bcd5 !important;
}

.jodit-placeholder {
  color: #b3bcd5 !important;
}

.jodit-wysiwyg p {
  color:#b3bcd5 !important;
}

#datePicker .react-calendar {
  background: #101217;
  border: 1px solid #2a3038;
  border-radius: 3px;
  color: #0090e7;
}

#datePicker .react-calendar__navigation button {
    font-family: 'Roboto',sans-serif;
    background: transparent;
    color: #b3bcd5;
    font-size: 0.9em;
    font-weight: bold;
}

#datePicker .react-calendar button.react-calendar__month-view__days__day {
  color:white;
}

#datePicker button.react-calendar__month-view__days__day--weekend {
  color:#ffab00 !important;
}

#datePicker .react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #757575 !important;;
}

#datePicker .react-calendar__tile--active {
  background: #00d25b;
}

#datePicker .react-calendar__tile--now {
  background: #2a3038;
}

#datePicker .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #2a3038;
}

.Dropdown-control {
  background: #2a3038 !important;
  border: 1px solid #2a3038 !important;
}

.react-date-picker__wrapper {
  background: #2a3038;
  color: #b3bcd5 !important;
  padding: 5px 10px;
  border: thin solid #2a3038 !important;
}

.react-date-picker__calendar-button svg, .react-date-picker__clear-button svg, .react-date-picker__inputGroup__input {
  stroke: #b3bcd5 !important;
  color:#b3bcd5 !important;
}

.container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
    box-sizing: border-box;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-4 {
    margin-left: 33.33333333%;
  }  

  .onset-4 {
    margin-right: 33.33333333%;
  } 

  @media (min-width: 576px) {
    .container-sm, .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md, .container-sm, .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg, .container-md, .container-sm, .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1320px;
    }
  }

  @media screen and (max-width:991px) {
    footer .row {
        flex-direction:column;
    }
}
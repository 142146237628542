@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/perpetua-titling-mt');
@import url('https://fonts.googleapis.com/css2?family=Damion&display=swap');

$paragraphFont: 'Quicksand',sans-serif;
$titleFont: 'Perpetua Titling MT', serif;
$titleWeight: 600;

$accentColor: #ff0076;
$pinkBrown: #ac949f;
$backgroundColor: #1c1f1f;
$offWhite: #e8e1cb;

$accentColorLight: lighten($accentColor, 5%);
$accentColorLighter: lighten($accentColor, 15%);

$accentColorDark: darken($accentColor, 5%);
$accentColorDarker: darken($accentColor, 15%);
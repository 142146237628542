@import "./../../assets/css/variables";

.goTopHidden {
    display:none;
}

.goTop {
    display:block;
}

.displayFlexColumn {
    display:flex;
    flex-direction:column;
}

.inschrijvenAnchor {
    display:inline-block;
    min-height:5em;
}

.courses {
    h1, h2, h3, h4 {
        font-family:$titleFont;
    }
}

form {
    div[id^="datePicker"] {
        button, button span {
            font-family:$paragraphFont;
        }
        button[class^="react-calendar__navigation__label"] {
            padding:0;
        }

        span[class^="react-calendar__navigation__label__labelText"] {
            font-size:0.8em;
        }
    button[class^="react-calendar__tile"] {
            color: white;
            text-transform: capitalize;
            font-family:$paragraphFont;
        }
    }
    margin-top: 25px;
    .row {
        display:flex;
        align-items: center;
        .colLg1 {
            width:8.33333%;
        }
        .colLg2 {
            width:16.6666%;
        }
    
        .colLg3 {
            width:25%;
        }
        .colLg4 {
            width:33.3333%;
        }
    
        .colLg6 {
            width: 50%;
        }
    
        .colLg9 {
            width:75%
        }
    
        .colLg10 {
            width:83.33333%;
        }
        .colLg11 {
            width:91.66666%;
        }
        .colLg12 {
            width:100%;
        }

        .colLg1, .colLg2, .colLg3, .colLg4, .colLg5, .colLg6, .colLg7, .colLg8, .colLg9, .colLg10, .colLg11, .colLg12 {
            padding: 0 10px;
            flex: 1;
        }
    }

    .success {
        display: inline-block;
        background: #78d0c6;
        border: 2px solid #003743;
        color: #004636;
        padding: 10px 20px;
    }

    .error {
        background: #d07896;
        border: 2px solid darkred;
        color: #b20000;
        padding: 10px 20px;
    }

    .formGroup {
        margin-bottom: 1rem;
        label {
            font-size: .875rem;
            line-height: 1;
            vertical-align: top;
            color:white;
            font-weight:500;
            svg {
                font-size:20px;
            }
        }

        .placeholder {
            position:relative;
            margin-bottom: 1.5em;
            &::after {
                position: absolute;
                left: 12px;
                top: 6px;
                content: attr(data-placeholder);
                pointer-events: none;
                opacity: 0.6;
                font-weight: 400;
                font-size:0.8em;
            }
        }

        div[class^="react-date-picker__wrapper"] {
            border-radius:3px;
            padding: 26px 12px 8px;
        }

        div[class^="react-date-picker"] {
            width: 100%;
        }

        div[class^="react-date-picker__inputGroup"] {
            text-align:left;
            min-width: calc(100% - 78px);
        }

        span[class^="Dropdown-arrow"] {
            right: 26px;
            top: 29px;
        }

        div[class^="Dropdown-control"], div[class^="Dropdown-control"] {
            padding: 28px 12px 10px;
        }

        div[class^="Dropdown-placeholder"] {
            text-align:left;
        }

        .formControl {
            display: block;
            width: 100%;
            box-sizing: border-box;
            cursor: default;
            outline: none;
            padding: 28px 12px 10px;
            font-family: inherit;
            background: #2e3136;
            border: 2px solid #2e3136;
            color: #b3bcd5;
            font-size: 1em;
            font-weight: 500;
            border-radius: 3px;
            &::-webkit-input-placeholder {
                position:absolute;
                top:5px;
                font-size:0.7em;
                font-weight:400;
                color:#b3bcd5;
            }

            &:focus {
                border: 2px solid $offWhite;
                background-color: #2a3038;
            }
        }
    }
}

.courses {
    background:$backgroundColor;
    color:$offWhite;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    section { 
        .sectionImg {
            max-width:100%;
            // margin-top: 3em;
        }
        p {
            font-weight:600;
        }
        a {
            border: 2px solid;
            display: inline-block;
            padding: 10px 20px;
            text-decoration: none;
            margin-top: 2em;
            font-family:$paragraphFont;
            font-weight: 600;
            transition:all ease-in-out .2s;
            &:hover {
                cursor:pointer;
                transform:scale(1.05);
                background:rgba(0, 0, 0, 0.2);
            }
        }
        .extraUrl {
            display: inline !important;
            border: none;
            padding: 0;
            font-weight: 900;
            color: inherit;
            &:hover {
                text-decoration:underline;
                transform:scale(1);
                background:none;
            }
        }
    }
    .backgroundImage {
        position:fixed;
        background-image:url(https://res.cloudinary.com/daanp/image/upload/f_auto,q_auto/v1697050022/bnr2b51hcqqbuicepooj.jpg);
        min-height: 100vh;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 80%;
        .overlay {
            background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(121,9,98,0.0) 31%, rgba(255,0,118,0) 100%);
            width:100%;
            height:100%;
            position:absolute;
        }
    }

    .header {
        position:relative;
        &::before {
            content: "";
            z-index: -1;
            width: 112%;
            left: -9px;
            top: -44px;
            height: 183px;
            transform: rotate(2deg);
            display: inline-block;
            position: absolute;
            background: $accentColor;
        }
        position:relative;
        z-index:3;   
        margin-top: 75vh;
        background:$accentColor;
        color:white;
        align-items: center;
        padding-bottom: 1.5em;
        i {
            display:block;
        }
        
        &::after {
            content: '';
            transform:rotate(180deg);
            background-size: 10px 5px;
            height: 5px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: auto;
            bottom: -5px;
            z-index: 1;
            background-image: linear-gradient(315deg, $accentColor 25%, transparent 25%), linear-gradient(45deg, $accentColor 25%, transparent 25%);        
        }
    }

    h4 {
        font-size:2em;
    }

    .firstCourseSection {
        position:relative;
        &::before {
            content: "";
            z-index: -1;
            width: 112%;
            left: -9px;
            top: -44px;
            height: 183px;
            transform: rotate(-2deg);
            display: inline-block;
            position: absolute;
            background: $accentColor;
        }
        position:relative;
        z-index:3;   
        background:$accentColor;
        font-family:$paragraphFont;
        color:white;
        align-items: center;
        padding-bottom: 3em;
        &::after {
            content: '';
            transform:rotate(180deg);
            background-size: 10px 5px;
            height: 5px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: auto;
            bottom: -5px;
            z-index: 1;
            background-image: linear-gradient(315deg, $accentColor 25%, transparent 25%), linear-gradient(45deg, $accentColor 25%, transparent 25%);        
        }
    }
    

    .secondCourseSection {
        
        div {
            z-index: 3;
            position: relative;
        }
        font-family:$paragraphFont;
        color:$backgroundColor;
        background:$offWhite;
        z-index: 1;
        padding-bottom: 1em;
        position: relative;
        margin-bottom:5em;
        &::after {
            content: "";
            z-index: 0;
            width: 112%;
            left: -9px;
            bottom: -44px;
            height: 183px;
            transform: rotate(2deg);
            display: inline-block;
            position: absolute;
            background: $offWhite;;
        }
    }

    .thirdCourseSection {
        font-family:$paragraphFont;
        background: $backgroundColor;
        position: relative;
        padding-top: 3em;
        margin-top: -5em;
        z-index: 0;
        padding-bottom: 90px;
    }
    h2 {
        font-family:$titleFont;
        font-weight:bold;
        font-size:2em;
        margin-bottom: 0;
    }
    h1 {
        font-size: 2em;
        &:after {
            background: none repeat scroll 0 0 $offWhite;
            bottom: -30px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 135px;
            // margin: 0 auto;
         }
    }

    .tierList {
        // background:rgb(29, 26, 26);
        padding:50px 0;
        .container {
        width: 100%;
        max-width:1450px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
        
        .row {
            display:flex;
            margin:0 -15px;
            .titleRow {
                margin-bottom:4em;
                h2 {
                    font-family:$paragraphFont;
                    font-size:3em;
                    margin-bottom:0;
                }
                p {
                    margin-top: 0.5em;
                }
            }
            .colLg12 {
                box-sizing: border-box;
                width:100%;
                padding:20px;
                .card {
                    padding:25px;
                    border-radius:5px;
                    .cardHeader {
                        background:white;
                        margin-top:-66px;
                        padding:20px;
                        text-align:center;
                        border-radius:5px;
                        color:#fdf9c5;
                        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.1);
                        h3 {
                            font-family:'Playfair Display', serif;
                            font-size:2em;
                            margin:0 0  1em 0;
                        }
                        p {
                            font-size: 1.5em;
                            margin: 0.5em;
                        }
                    }

                    .cardbody {
                        font-weight:600;
                        text-align:center;
                        padding:20px;
                        .button {
                            padding: 15px 50px;
                            text-align:center;
                            border: 2px solid white;
                            color:white;
                            border-radius:50px;
                            display:inline-block;
                            margin-top:15px;
                            transition: all 0.3s ease-in-out;
                            &:hover {
                                background:white;
                                transition: all 0.3s ease-in-out;
                                cursor:pointer;
                            }
                        }
                    }
                }

                .bodyBg1 { 
                    background-color:$accentColor;
                    .cardHeader {
                        color: $accentColor;
                    }
                    .button:hover {
                        transition: all 0.3s ease-in-out;
                        color: $accentColor;
                    }
                }
                .bodyBg2 { 
                    background-color:#a200ff;
                    .cardHeader {
                        color: #a200ff;
                    }
                    .button:hover {
                        transition: all 0.3s ease-in-out;
                        color: #a200ff;
                    }
                }
                .bodyBg3 { 
                    background-color:#ff5100;
                    .cardHeader {
                        color: #ff5100;
                    }
                    .button:hover {
                        transition: all 0.3s ease-in-out;
                        color: #ff5100;
                    }
                }
                .bodyBg4 { 
                    background-color:#2a9d8f;
                    .cardHeader {
                        color: #2a9d8f;
                    }
                    .button:hover {
                        transition: all 0.3s ease-in-out;
                        color: #2a9d8f;
                    }
                }
            }
        }
        }
    }

        .container {
        margin-right: auto;
        margin-left: auto;
        max-width:1450px;
        .row {
            display:flex;
            margin:0 -10px;
            .column {
                padding: 0 10px;
                display: flex;
                flex-direction: column;
                }

            .colLg20 {
                width:20%;
            }
        }
    }
}

.privacyDisclaimer {
    font-weight: 400;
    display: block;
    margin-bottom: 1em;
    font-size: 0.8em;
}

@media screen and (max-width:991px) {
    .courses {
        .colLg4, .colLg1, .colLg2, .colLg6 {
            width:50% !important;
        }

        .row {
            flex-direction:column;
        }
    }

    .container {
        max-width: calc(100% - 30px) !important;
    }
}

@media screen and (max-width:768px) {
    .courses {
        .colLg4, .colLg1, .colLg2, .colLg6 {
            width:100% !important;
        }
    }

    .mobileFlexOrder1 {
        order: 1;
    }

    .mobileFlexOrder2 {
        order: 2;
    }

    .mobileFlexOrder3 {
        order: 3;
    }

    div[class*="offset-4"] {
        margin-left:0 !important;
    }

    .backgroundImage {
        width: 128% !important;
        background-size: contain !important;
        background-position: -50px 0% !important;
    }

    .overlay {
        display:none;
    }

    .header {
        margin-top: 37vh !important;
    }
}

.hidden {
    display:none;
}
@import "./../../assets/css/variables";

.footer {
    h1, h2, h3, h4, h5, h6 {
        font-family:$titleFont;
      }
    position:relative;
    z-index:2;
    font-family:$paragraphFont;
   .footerBody {
    padding:2em 0;
    background:$accentColor;
    color:white;
    display:flex;
   }
    h6 {
        font-size:1.5em;
        margin-bottom: 1em;
        margin-top: 1em;
        &::after {
            margin-top: 1em;
            content: "";
            width: 100px;
            height: 2px;
            display: block;
            background: #ff4d9f;
        }
    }
    .colOne {
        width:40%;
        img {
            max-width: 250px;
        }
        .sponsors {
            margin-top:2em;
            .logoDanssport {
                background:white;
            }
            img {
                width: 50%;
                max-width: 150px;
            }
        }

        
        
    }
    .colTwo {
        width:30%;
        .contactLocation {
            display:flex;
            align-items:center;
            a {
                display:block;
            }
            .iconHolder {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
            }
            svg {
                font-size: 1.5em;
                color: $backgroundColor;
                // margin-right: 1em;
            }
        }

        .socials {
            margin-top: 2em;
            display:flex;
            h5 {
                margin:0 5px;
            }
            
            a {
                font-size:2em;
                margin:0 5px;
                background: #be035a;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                svg {
                    color: white;
                    transition:all ease-in-out .2s;
                }
                &:hover {
                    svg {
                        color:white;
                        transform: scale(1.15);
                    }
                }
            }
        }

        .contactLinks {
            a {
                display:block;
            }
            svg {
                margin-right:5px;
            }
        }
    }
    a, p {
        margin:1em 0;
        text-decoration:none;
        color:inherit;
    }

    .footerFooter {
        background: #be035a;
        color: #ff8dc2;
        text-align: center;
    }
}

@media screen and (max-width:991px) {
    footer {
        .colOne, .colTwo {
            width:100% !important;
        }
    }
}
.selectInfo {
  margin-top: 15px;
  .selectItem {
    p {
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
      background-color: #101217;
      transition-duration: 0.2s;
      transition-property: background-color;
    }
  }

  .selectedSection {
    background-color: #101217 !important;
  }
}

.width100 {
  width: 100%;
}

.joditEditor {
  background: #2a3038;
}

.removeDance p {
  color: #fc424a !important;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.addDance {
  transition:all ease-in-out .2s;
  background: #36ed36;
  width: 50px;
  height: 50px;
  color: black;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-bottom:10px;
  &:hover {
    background:#1a881a;
    cursor:pointer;
  }
}

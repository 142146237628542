@import "./../../assets/css/variables";

.goTopHidden {
    display:none;
}

.goTop {
    display:block;
}

.blogs {
    padding-top: 100px;
    background:$backgroundColor;
    font-family:$paragraphFont;
    color:$offWhite;
    
    h1 {
        font-size: 3em;
    }
    h1, h2 {
        font-family:$titleFont;
        font-weight:$titleWeight;
        &:after {
            background: none repeat scroll 0 0 $offWhite;
            bottom: -22px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 65px;
            // margin: 0 auto;
         }
    }
    .blogPostOverview {
                color:inherit;
        a {
            text-decoration: none;
            .overview {
                position:relative;
                .eventFlag {
                    position:absolute;
                    top:0;
                    right:1em;
                    background:$accentColor;
                    color:white;
                    font-weight:bold;
                    font-size: 0.75em;
                    padding: 5px 15px;
                }
                background:$pinkBrown;
                margin-bottom: 3em;
                color:black;
                box-shadow:  4px 4px 8px #181a1a,
                -4px -4px 8px #202424;
                border-radius:4px;
                overflow:hidden;
                img {
                    background:$backgroundColor;
                    display: block;
                    width: 100%;
                    height: 20vh;
                    object-fit: cover;
                }
                .overviewBody {
                    padding: 10px;
                    h3 {
                        margin-top:0;
                        margin-bottom:0;
                    }
                    .date {
                        color:grey;
                        font-size:.8em;
                        margin-top: 1em;
                        display: inline-block;
                    }

                    .line {
                        margin-top:10px;
                        padding-top: 10px;
                        width: 100%;
                        border-top: 1px solid #d7d7d7;
                    }
                    button {
                        display: block;
                        margin-top: 1em;
                        padding: 10px 20px;
                        background:$accentColor;
                        border: 2px solid $accentColor;
                        color:white;
                        font-weight: bold;
                        font-family: inherit;
                        margin-right: 0;
                        margin-left: auto;
                        &:hover {
                            cursor:pointer;
                            background: transparent;
                            
                            color: $accentColor;
                            transition:.3s all ease-in-out;
                        }
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width:768px) {
    .blogs {
        padding-top: 0em !important;
        h1 {
            margin-top:2em;
        }
    }
}
@import "./../../assets/css/variables";

.events {
    .goTopHidden {
        display:none;
    }
    
    .goTop {
        display:block;
    }
    padding-top: 100px;
    background:$backgroundColor;
    color:$offWhite;
    padding-bottom: 5em;
    h1 {
        font-size: 3em;
    }
    h1, h2 {
        font-family:$titleFont;
        font-weight:$titleWeight;
        &:after {
            background: none repeat scroll 0 0 $offWhite;
            bottom: -30px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 65px;
            // margin: 0 auto;
         }
    }

        .container {
        margin-right: auto;
        margin-left: auto;
        max-width:1250px;
        padding: 0 15px;
        .row {
            display:flex;
            margin:0 -10px;
            .column {
                padding: 0 10px;
                display: flex;
                }

            .colLg20 {
                width:20%;
            }
        }
    }

    .month {
        font-family:inherit;
        position:relative;
        width:100px;
        height:180px;
        border: 3px solid $backgroundColor;
    }

    .currentMonth {
        background:#ac949f !important;
        color:white;
        &:hover {
            background-color:#ac949f !important;
        }
    }

    .usualDay {
        // background: #ac949f;
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        &:hover {
            background-color:inherit;
        }
    }

    .otherMonth {
        background:#292c2c;
        &:hover {
            background-color:#ac949f !important;
        }
        &:active {
            background-color:#ac949f !important;
        }
    }

    .hideMonth {
        display:none;
    }

    .eventImage {
        box-sizing:border-box;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        position:absolute;
        top: 0;
        left: 0;
        .fader {
            padding:20px 20px 0 20px;
            // background-color: rgba(28, 31, 31, 0.5);
            background-color: rgba(94, 94, 94, 0.5);
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .dateFlag {
                position:absolute;
                background:$accentColor;
                color:white;
                font-weight:bold;
                padding:5px;
                top:0;
                right:8px;
            }
            h4 {
                margin-top:0;
                margin-bottom: 4.5em;
                text-transform:uppercase;   
            }

            .subTitle {
                font-size:1em;
                padding-bottom:0;
                margin-top:0;
                margin-bottom:0;
            }

            .footerText {
                position:absolute;
                bottom:2em;
            }
        }
        
    }
}


.pageOptionWrapper {
    background:$offWhite;
    position: absolute;
    width: 101%;
    height: 100%;
    left: 0;
    top: 0;
    .dateFlag {
        position: absolute;
        background: $accentColor;
        color: white;
        font-weight: bold;
        padding: 5px;
        top: 0;
        right: 8px;
    }
}
.pageOption {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .option, .option img { width: 100%; height: 100%; }
  .option {
    overflow: hidden;
    position: absolute;  
    /* arctan(27 / 40) = 34.01935deg 
     * need to skew by 90deg - 34.01935deg = 55.98065deg
    */
    transform: skewX(-44.5deg);
  }
  .option:first-child {
    left: 0;
    transform-origin: 100% 0;
    &::after {
        text-align:left;
        margin: 0.5em 5em 0.5em 0.5em;
    }
  }
  .option:last-child {
    right: 0;
    transform-origin: 0 100%;
    &::after {
        text-align:right;
        margin: 0.5em 0.5em 0.5em 5em;
    }
  }
  .option img { opacity: .75; }
  .option img, .option:after {
    transform: skewX(44.5deg);
    transform-origin: inherit;
  }
  .option:after {
    font-size:12px;
    position: absolute;
    color: white;
    content: attr(data-inf);
    font-weight:bold;
  }
  .option:first-child:after { top: 0; left: 6px; }
  .option:last-child:after { right: 6px; bottom: 0; }

@media screen and (min-width:769px) and (max-width:1199px) {
    .events {
        max-width:900px;
        margin:0 auto;
        .option {
            transform: skewX(-80.7deg);
          }
        .option img, .option:after {
            transform: skewX(80.7deg);
        }
        
        div[class^="react-calendar__month-view__days"] {
            flex-direction:column !important;
        }
        div[class^="react-calendar__month-view__weekdays__weekday"] {
            display:none;
        }
        .month {
            width: 100%;
            min-height: 150px;
        }

        .otherMonth {
            display:none;
        }

        .usualDay {
            flex-direction:column;
        }

        .mobileWeekday {
            color: $accentColorDarker;
        }
        abbr {display:none;}
        .option:first-child::after {
            left:43px;
        }

        .option:last-child::after {
            right:51px;
        }

        .dateFlag {
            right:34px !important;
        }
    }
}

@media screen and (max-width:768px) {
    .events {
        max-width:395px;
        margin:0 auto;
        padding-top: 0em !important;
         h1 {
            font-size: 2em;
            margin-bottom: 2em;
        }
        div[class^="react-calendar__month-view__days"] {
            flex-direction:column !important;
        }
        div[class^="react-calendar__month-view__weekdays__weekday"] {
            display:none;
        }

        div[class^="react-calendar__navigation"] button {
            font-size:1em !important;
        }

        .month {
            width: 100%;
            min-height: 150px;
        }

        .otherMonth {
            display:none;
        }

        .usualDay {
            flex-direction:column;
        }

        .mobileWeekday {
            color: $accentColorDarker;
        }
        abbr {display:none;}
        
        .option {
            transform: skewX(-69deg);
          }
        .option img, .option:after {
            transform: skewX(69deg);
        }

        .option:first-child::after {
            left:19px;
        }

        .option:last-child::after {
            right:19px;
        }
    }
}

@media screen and (min-width:1199.01px) {
    .mobileWeekday {
        display:none;
    }
}
.activeBlogPost {
    h1 {
        font-size: 2em !important;
        margin-top: 0;
        margin-bottom:1em;
    }
    background: #141717;
    padding:20px;
    box-shadow:  4px 4px 8px #181a1a,
            -4px -4px 8px #252727;
    border-radius:4px;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    margin-bottom:2em;
}
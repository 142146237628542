.selectInfo {
    margin-top:15px;
    .selectItem {
        p {
            font-size:12px;
        }
        &:hover {
            cursor:pointer;
            background-color:#101217;
            transition-duration:.2s;
            transition-property:background-color;
        }
    }

    .selectedSection {
        background-color:#101217 !important;
    }
}

.width100 {
    width:100%;
}

.joditEditor {
    background:#2a3038;
}
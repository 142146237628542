@import "./../../assets/css/variables";

.goTop {
    position: fixed;
    z-index: 5;
    padding: 20px;
    border-radius: 50px;
    background: #ff0076;
    color: black;
    border: none;
    box-shadow: -4px 4px 8px rgba(28, 31, 31, 0.2), 4px -4px 8px rgba(28, 31, 31, 0.2);
    bottom: 12px;
    right: 12px;
    transition: all .2s ease-in-out;
    &:hover {
        background:$accentColorDarker;
        cursor:pointer;
    }
}
// subtext color: #6c7293

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

.mr10 {
  margin-right: 10px;
}
.barsIcon {
  display: none;
}

.sideMenu {
  max-width: 300px;
}

.transition {
  transition: all ease-in-out 0.2s;
}

.minWidth176 {
  min-width: 176px;
}

.timerDropdown {
  margin-right: 22px;
}
.dropdown > div,
.timerDropdown > div {
  border-radius: 0;
  background-color: #2a3038;
  border-color: #191c24;
  * {
    color: #b3bcd5 !important;
  }
}

.overflowHidden {
  overflow: hidden;
}

.container {
  font-family: "Roboto", sans-serif;
  background: #191c24;
  // overflow: hidden;
  display: flex;
  padding-top: 50px;
  color: #6c7293;
  min-height: calc(100vh - 50px);
  .colLg1 {
    width: 8.33333%;
  }
  .colLg2 {
    width: 16.6666%;
  }

  .colLg3 {
    width: 25%;
  }
  .colLg4 {
    width: 33.3333%;
  }

  .colLg6 {
    width: 50%;
  }

  .colLg9 {
    width: 75%;
  }

  .colLg10 {
    width: 83.33333%;
  }
  .colLg11 {
    width: 91.66666%;
  }
  .colLg12 {
    width: 100%;
  }

  .backButton {
    background: #12151e;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 15px;
    svg {
      margin-right: 10px;
    }
    &:hover {
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      background: black;
      color: white;
    }
  }

  .profileInfo {
    margin-left: 25px;
    position: relative;
    display: flex;
    img {
      border-radius: 50px;
    }
    h4 {
      margin: 0 0 0 20px;
      color: white;
    }
    p {
      margin: 5px 0 0 20px;
      font-size: 12px;
    }
    .online {
      position: absolute;
      background: #00d25b;
      border-radius: 50px;
      height: 5px;
      width: 5px;
      bottom: 5px;
      left: 30px;
    }
  }
  .nav {
    list-style-type: none;
    width: 200px;
    padding-left: 0;
    margin-right: 50px;
    li.active a {
      background: #0f1015;
      border-left: 3px solid #0090e7 !important;
      color: white;
      transition-duration: 0.2s;
      transition-property: color;
    }
    li a {
      border-left: 3px solid #191c24;
      span {
        background: #292d39;
        font-size: 12px;
        padding: 8px;
        border-radius: 50px;
        display: inline-block;
        margin-right: 20px;
      }
      .dashboardIcon {
        color: #0090e7;
      }
      .websiteIcon {
        color: #ffab00;
      }
      color: #6c7293;
      box-sizing: border-box;
      width: 100%;
      text-decoration: none;
      display: inline-block;
      padding: 10px 16px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      &:hover {
        background: #0f1015;
        border-left-color: #0f1015;
        cursor: pointer;
        color: white;
        transition-duration: 0.2s;
        transition-property: color;
      }
    }
  }

  .contentBlock {
    background: black;
    width: calc(100% - 200px);
    padding: 15px;
    .row {
      width: 100%;
      display: flex;
      .smallBlock h4 {
        margin: 0 !important;
      }
      .block {
        display: flex;
        border-radius: 5px;
        padding: 20px;
        margin: 15px;
        background: #191c24;
        h3,
        h4 {
          color: white;
          margin: 0 0 10px 0;
        }
        p {
          margin: 0;
          color: #6c7293;
        }

        .infoCircle,
        .textIcon,
        .menuIcon,
        .groupIcon {
          margin-right: 15px;
        }

        .infoCircle {
          color: #ffab00;
        }

        .textIcon {
          color: #8f5fe8;
        }

        .menuIcon {
          color: #fc424a;
        }

        .groupIcon {
          color: #0090e7;
        }
      }

      .clickableBlock:hover {
        cursor: pointer;
        background-color: #101217;
        transition-duration: 0.2s;
        transition-property: background-color;
      }

      .activeBlock {
        background-color: #0f1015;
      }
    }

    .bgDarkBlock {
      background: #12151e;
      padding: 20px;
      margin: 15px 0;
      width: 100%;
      box-sizing: border-box;
    }

    .bgDarkBlockNoPadding {
      background: #12151e;
      margin: 15px 0;
      p {
        padding: 20px;
      }
      .cross {
        color: #fc424a;
        font-weight: bold;
        p {
          margin-left: -14px;
          padding: 18px 0;
          text-align: center;
        }
      }
    }

    .textAlignRight {
      text-align: right;
    }

    .clickableEntity {
      display: flex;
      p {
        color: white !important;
        font-weight: bold;
      }
      .cross p {
        color: #fc424a !important;
        font-weight: bold;
      }
      transition: all ease-in-out 0.2s;
      &:hover {
        background: black;
        cursor: pointer;
      }
    }
  }

  form {
    margin-top: 25px;
    .row {
      display: flex;
      align-items: center;
    }

    .formGroup {
      margin-bottom: 1rem;
      div[class^="react-select-"] {
        position: relative !important;
      }
      label {
        font-size: 0.875rem;
        line-height: 1;
        vertical-align: top;
        color: white;
        font-weight: 500;
        svg {
          font-size: 20px;
        }
      }

      .formControl {
        box-sizing: border-box;
        border: 1px solid #2c2e33;
        font-weight: 400;
        font-size: 0.875rem;
        padding: 0.625rem 0.6875rem;
        background-color: #2a3038;
        border-radius: 2px;
        display: block;
        width: 100%;
        color: #495057;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        outline-width: 0;
        outline-color: transparent;
        box-shadow: none;
        outline-style: none;
        color: #b3bcd5;
        &:focus {
          border: 1px solid rgba(71, 164, 71, 0.5);
          background-color: #2a3038;
        }
      }
    }
  }

  .button {
    background: #0090e7;
    padding: 5px 20px;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    display: inline-block;
    height: 20px;
    line-height: 20px !important;
    &:hover {
      cursor: pointer;
      background-color: #0078c1;
      transition: background-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
  }
}

.success {
  color: #00d25b !important;
  font-size: 13px;
  margin-bottom: 15px !important;
}

.flexColumn {
  flex-direction: column;
}

.error {
  color: #fc424a !important;
  font-size: 13px;
  margin-top: 15px;
}

// .mobileFlexRow {
//     display:flex;
// }

.displayFlex {
    display:flex;
}

@media screen and (max-width: 1091px) {
  .container {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
  }
  .row {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .mobileFlexRow {
    display: flex;
    .colLg1 {
        width:50%;
    }
  }

  .colLg2,
  .colLg3,
  .colLg4,
  .colLg6,
  .colLg9,
  .colLg10 {
    width: 100% !important;
  }

  .startuurMobileLabel {
    grid-column: span 2;
  }

  .contentBlock {
    width: 100% !important;
    box-sizing: border-box;
    form {
      padding: 0 !important;
      .formGroup {
        label {
          margin-bottom: 10px;
        }
        textarea.formControl {
          min-height: 77px;
        }
      }
    }
  }
  .profileInfo {
    position: absolute !important;
    top: 0;
    background: #191c24;
    width: calc(100% - 34px);
    margin-left: 0 !important;
    padding: 17px;
    .online {
      bottom: 21px !important;
      left: 43px !important;
    }
  }

  .sideMenu {
    max-width: 67px;
    transition: max-width 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .navigation {
    display: none;
  }

  .barsIcon {
    font-size: 1.5em;
    display: block;
    margin-left: auto;
  }

  .closedSideMenu {
    max-width: 0;
  }
}

.uploadImage {
  img {
    display: block;
  }
  .imageWrapper {
    display: inline-block;
    position: relative;
    div {
      transition: all 0.2s ease-in-out;
      text-align: center;
      padding: 20px;
      box-sizing: border-box;
      opacity: 0;
      top: 0;
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      color: black;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
      div {
        opacity: 1;
      }
    }
  }
}

.login {
    padding-top:200px;
    height: calc(100vh - 200px);
    background-color: #383d4c;
    .loginForm {
    padding: 30px;
    max-width: 50vmin;
    margin: 0 auto;
    border-radius: 5px;
    background: #191c24;
    // box-shadow: 5px 5px 10px #101217, -5px -5px 10px #292d39;

    h2 {
        margin-bottom:20px;
        text-align:center;
        color:white;
    }
    .field {
        margin-bottom: 15px;
        margin-top: 15px;
        letter-spacing: -.2px;
        border: 0;
        outline: 0;
        font-size: 15px;
        font-weight:600;
        color: #c7a86f;
        border-radius: 50px;
        padding: 16px 25px;
        background-color: #191c24;
        // text-shadow: 1px 1px 0 #fff;
        box-shadow: inset 2px 2px 8px #101217, inset -5px -5px 10px #292d39;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        &:focus {
            box-shadow: inset 1px 1px 2px #101217, inset -1px -1px 2px #292d39;
        }
        &::-webkit-input-placeholder {
            color:white;
            font-weight:600;
            font-size:12px;
            opacity:0.7;
            transition: opacity 0.35s ease-in !important;
        }

        &:focus::-webkit-input-placeholder  {
            transition: opacity 0.35s ease-in-out !important;
        opacity: 0.0;
        }

        /* Change the white to any color */
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: inset 1px 1px 2px #101217, inset -1px -1px 2px #292d39;
            background-color: #191c24 !important;
            color: #c7a86f !important;
        }

        // &:-webkit-autofill {
        //     -webkit-box-shadow:0 0 0 50px rgb(0, 0, 0) inset; /* Change the color to your own background color */
        //     -webkit-text-fill-color: #333;
        // }
        // &:-webkit-autofill:focus {
        //     -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
        //     -webkit-text-fill-color: #333;
        // } 
    }

    .submit {
        margin-top: 20px;
        width: 100%;
        padding: 15px;
        border-radius: 50px;
        border: none;
        background: #078ee7;
        color: white;
        font-weight: bold;
        font-size: 16px;
        transition: background 0.25s ease-in !important;
        &:focus {
            outline: none !important;
        }
        &:hover {
            cursor:pointer;
            background: #097ac5;
            transition: background 0.35s ease-in-out !important;
        }
    }

    .messageHolder {
        min-height:25px;
        text-align:center;
        .warning {
            color:#ef6767;
        }
        .success {
            color:#299a29;
        }
    }
    }
}

@import "./../../assets/css/variables";

.schedule {
    background:$backgroundColor;
    color:$offWhite;
    h1 {
        font-size: 3em;
    }
    h1, h2 {
        font-family:$titleFont;
        font-weight:$titleWeight;
        &:after {
            background: none repeat scroll 0 0 $offWhite;
            bottom: -30px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 65px;
            // margin: 0 auto;
         }
    }

    .scheduleSection {
        width:100%;
        margin-top:2em;
    }

    .container {
        margin-right: auto;
        margin-left: auto;
        max-width:1450px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        padding-bottom:3em;
        .row {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-right: -10px;
            margin-left: -10px;
            .column {
                padding: 0 10px;
                display: flex;
                box-sizing: border-box;
                .innerBox {
                    margin-top: 2em;
                    position: relative;
                    display: block;
                    background: #ffffff;
                    box-shadow: 0px, 0px, 15px, 2px rgba(0, 0, 0, 0.05);
                    width: 100%;
                    .boxHeader {
                        h3 {
                            margin-top: 0;
                        }
                        position: relative;
                        // padding: 22px 30px;
                        color:black;
                        .classCat {
                            position: absolute;
                            display: block;
                            right: 0px;
                            top: 22px;
                            z-index: 2;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 3px 15px;
                            line-height: 24px;
                            color: #ffffff;
                            background: #ff0076;
                            border-radius: 15px 0px 0px 15px;
                            text-decoration:none;
                        }
                    }

                    figure {
                        margin:0;
                        position: relative;
                        overflow:hidden;
                        img {
                            display: block;
                            width: 100%;
                            height: 20vh;
                            object-fit: cover;
                           
                        }
                        .overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background: transparent;
                            transition: all ease-in-out .3s;
                            z-index: 1;
                        }
                        &:hover {
                            .overlay {
                                background:rgba(0, 0, 0, 0.15);
                            }
                            img {
                                transition: all ease-in-out .3s;
                                transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
                            }
                        }
                    }

                    .boxContent {
                        color: black;
                        padding: 25px 20px;
                        font-size: 14px;
                        .classInfo {
                            margin:0;
                            padding:0;
                            list-style-type:none;
                            li {
                                svg {
                                    color:$accentColor;
                                }
                                display:flex;
                                justify-content: space-around;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                border-bottom: 1px solid lightgrey;
                                &:last-child {
                                    border-bottom:none;
                                }
                                .timeSlots {
                                    display:flex;
                                    flex-direction:column;
                                }

                                .title {
                                    width:50%;
                                }
                            }
                        }
                    }
                }
            }

            .colLg20 {
                width:20%;
            }
        }
        .row > * {
            flex-shrink: 0;
            width: 100%;
            max-width: 100%;
            padding-right: 10px;
            padding-left: 10px;
          }
    }
}

@media screen and (min-width:991.01px) {
    .container {
        padding-top:5em;
        padding-bottom:5em;
    }
}

@media screen and (max-width:1350px) {
    .container {
        max-width:1150px;
    }

    .colLg20 {
        width:33.333% !important;
    }
}

@media screen and (max-width:1200px) {
    .colLg20 {
        width: 50% !important;
    }
}

@media screen and (max-width:768px) {
    .colLg20 {
        width: 100% !important;
    }
    .schedule h1 {
        margin-top:2em;
        font-size:2em !important;
    }
}



.selectInfo {
  margin-top: 15px;
  .selectItem {
    p {
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
      background-color: #101217;
      transition-duration: 0.2s;
      transition-property: background-color;
    }
  }

  .selectedSection {
    background-color: #101217 !important;
  }
}

.width100 {
  width: 100%;
}

.joditEditor {
  background: #2a3038;
}

.copyLink {
  padding: 10px;
  div {
    transition: all ease-in-out .2s;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #191c24;
    color: white;
    &:hover {
        cursor:pointer;
        background:#0e1014;
    }
  }
}
